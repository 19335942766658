// extracted by mini-css-extract-plugin
export var column = "LocationsIndexLocalities__column__F0AdJ";
export var flex = "LocationsIndexLocalities__flex__qeuhk";
export var flexColumn = "LocationsIndexLocalities__flexColumn__ASUA8";
export var gap1 = "LocationsIndexLocalities__gap1__n0mdO";
export var gap2 = "LocationsIndexLocalities__gap2__jXnt0";
export var gap3 = "LocationsIndexLocalities__gap3__XHber";
export var gap4 = "LocationsIndexLocalities__gap4__GlXXm";
export var gap5 = "LocationsIndexLocalities__gap5__qCG95";
export var localitiesContainer = "LocationsIndexLocalities__localitiesContainer__M0pZH";
export var localitiesList = "LocationsIndexLocalities__localitiesList__GdevK";
export var providersList = "LocationsIndexLocalities__providersList__cBC_F";
export var row = "LocationsIndexLocalities__row__bLrBs";